import React from 'react'
import styled from 'styled-components'
import { Link } from '../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general'

const StyledDiv = styled.div`
width: 100%;
display:flex;
justify-content:center;
align-content: center;
align-items: center;
flex-direction: row;
padding: 10px;
font-size: 14px;

a {
	color: #002500;
	text-decoration: none;
	background: transparent;
  }
  a:hover {
	color: #002500;
	text-decoration: none;
  }
  ::-webkit-input-placeholder {
	color: inherit;
	opacity: 0.5;
  }
  :-moz-placeholder {
	color: inherit;
	opacity: 0.5;
  }
  :-ms-input-placeholder {
	color: inherit;
	opacity: 0.5;
  }
  ::-ms-input-placeholder {
	color: inherit;
	opacity: 1;
  }
  .announcement-link {
	color: #000000;
  }
  .announcement-link:active,
  .announcement-link:hover {
	color: #000000;
  }
  .announcement-text {
	font-weight: 500;
	text-transform: none;
	letter-spacing: 0.05em;
	font-size: 1.3em;
	font-size: 16px;
	font-weight: bold;

  }
  .announcement-link-text {
	font-size: 14px;
  }
  @media only screen and (min-width: 769px) {
	.announcement-slider--compact .announcement-link-text,
	.announcement-slider--compact .announcement-text {
	  display: inline;
	}
	.announcement-slider--compact .announcement-text + .announcement-link-text {
	  padding-left: 5px;
	}
  }
  
  a[href="/products/buy-prolon"]:after {
	content: none !important;
  }
  
`
export default function AnnouncementBar() {
    return (
        <StyledDiv>
			<Link className="announcement-link" to="/products/buy-prolon" tabindex="0">
			<span className="announcement-text">#selflove — Fall In Love With Fasting — Save Up to $109 •</span>
			<span className="announcement-link-text">Automatic savings in cart</span>
			</Link>
        </StyledDiv>
    )
}
